<template>
  <div>
    <base-material-tabs color="secondary" class="my-5 reduce-zoom" grow>
      <v-tab v-for="(tab, i) in tabs" :key="i">
        {{ tab }}
      </v-tab>
      <v-tab-item>
        <v-row class="mt-1 summary" ref="aaa">
          <v-col cols="12" sm="12" md="3" lg="3">
            <total-admins-statistics-card class="my-12" ref="bbb" />
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3">
            <total-employers-statistics-card class="my-12" />
          </v-col>

          <v-col cols="12" sm="12" md="3" lg="3">
            <total-employees-statistics-card class="my-12" />
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- <v-tab-item> <all-stats-card /> </v-tab-item> -->
    </base-material-tabs>
  </div>
</template>

<script>
import TotalAdminsStatisticsCard from "../../components/Base/StatsCards/TotalAdminsStatisticCard.vue";
import TotalEmployeesStatisticsCard from "../../components/Base/StatsCards/TotalEmployeesStatisticsCard.vue";
import TotalEmployersStatisticsCard from "../../components/Base/StatsCards/TotalEmployersStatisticsCard.vue";

export default {
  components: {
    TotalAdminsStatisticsCard,
    TotalEmployeesStatisticsCard,
    TotalEmployersStatisticsCard,

    // AllStatsCard
  },
  data() {
    return {
      tab: 0,
      tabs: ["Summary Stats", "Employers Stats"],
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
